import React from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import TextIntro from '@/components/TextIntro'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Index: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    titleText: t('dna.home_header', 'fill copy key: dna.home_header'),
    paragraphText: t('dna.home_body', 'fill copy key: dna.home_body'),
  }

  return (
    <Page
      content={
        <PageWrapper>
          <TextIntro {...content} />
        </PageWrapper>
      }
      settings={{
        backgroundColor: '#5a7d69',
        contentAnimationVariants: pageFadeInOut(),
        useCircularWipe: false,
      }}
    />
  )
}

export default Index
